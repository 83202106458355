import env from "config/env";
import { useEffect, useState } from "react";

export const ZENDESK_STORAGE_KEY = "zauth";

const startAuthFlow = () => {
  const endpoint = `${env.zendeskUrl}/oauth/authorizations/new`;
  const url_params = `?response_type=token&redirect_uri=${
    env.redirectUri
  }&client_id=bonver_integration_for_zendesk&scope=${encodeURIComponent(
    "read write",
  )}`;
  window.location.href = endpoint + url_params;
};

export const useZendesk = () => {
  const [token, setToken] = useState<string | null>();

  useEffect(() => {
    const currentToken = window.localStorage.getItem(ZENDESK_STORAGE_KEY);
    if (currentToken) {
      setToken(currentToken);
    } else {
      startAuthFlow();
    }
  }, []);

  return token;
};
