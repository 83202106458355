import { useIsAuthenticated } from "@azure/msal-react";
import { useToggle } from "@react-hookz/web";
import { loginRequest } from "config/auth";
import { useRouter } from "next/router";
import { msalInstance } from "pages/_app";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

export const TOKEN_STORAGE_KEY = "accessToken";

export const getAccessToken = () =>
  window.localStorage.getItem(TOKEN_STORAGE_KEY);

export const useUpdateToken = () => {
  const isAuthenticated = useIsAuthenticated();
  const router = useRouter();
  const [isLoaded, toggleLoaded] = useToggle(false);
  const [, setCookie, removeCookie] = useCookies([TOKEN_STORAGE_KEY]);

  useEffect(() => {
    const getToken = async () => {
      const account = msalInstance.getActiveAccount();

      if (account) {
        let response;

        const config = {
          ...loginRequest,
          account: account,
        };

        try {
          response = await msalInstance.acquireTokenSilent(config);
        } catch (error) {
          console.error(error);
          response = await msalInstance.acquireTokenRedirect(config);
        }

        if (response && response.accessToken) {
          // We set the token in two locations:
          // - Cookie: Server access
          // - LocalStorage: Client access
          try {
            setCookie(TOKEN_STORAGE_KEY, response.accessToken, {
              path: "/",
              maxAge: 3600 * 2,
              sameSite: true,
            });

            window.localStorage.setItem(
              TOKEN_STORAGE_KEY,
              response.accessToken,
            );
            toggleLoaded(true);
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        removeCookie(TOKEN_STORAGE_KEY);
        window.localStorage.removeItem(TOKEN_STORAGE_KEY);
        router.push("/");
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated]);

  return isLoaded;
};
