import env from "./env";

export const loginRequest = {
  scopes: ["openid", "profile", "email", "offline_access", env.accessScope],
};

export const msalConfig = {
  auth: {
    clientId: env.clientId,
    authority: env.authority,
    knownAuthorities: ["bonverb2c.b2clogin.com"],
    redirectUri: env.redirectUri,
    postLogoutRedirectUri: env.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};
