import {
  B2C_AUTHORITY_URL,
  B2C_CLIENT_ID,
  DEV_ACCESS_SCOPE,
  DEV_BASE_URL,
  PROD_ACCESS_SCOPE,
  PROD_BASE_URL,
} from "./constants";

export type Env = "kraft" | "test" | "production" | "local";

const envs = {
  local: {
    name: "local",
    clientId: B2C_CLIENT_ID,
    authority: B2C_AUTHORITY_URL,
    redirectUri: "http://localhost:8080",
    accessScope: DEV_ACCESS_SCOPE,
    apiUrl: DEV_BASE_URL,
    zendeskUrl: "https://support.bonver.com",
  },
  kraft: {
    name: "kraft",
    clientId: B2C_CLIENT_ID,
    authority: B2C_AUTHORITY_URL,
    redirectUri: "https://insight.bonver.com",
    accessScope: PROD_ACCESS_SCOPE,
    apiUrl: PROD_BASE_URL,
    zendeskUrl: "https://support.bonver.com",
  },
  test: {
    name: "test",
    clientId: B2C_CLIENT_ID,
    authority: B2C_AUTHORITY_URL,
    redirectUri:
      "https://bonver-customerportal-frontend-test.azurewebsites.net",
    accessScope: DEV_ACCESS_SCOPE,
    apiUrl: DEV_BASE_URL,
    zendeskUrl: "https://support.bonver.com",
  },
  production: {
    name: "production",
    clientId: "3fa90bd6-6108-4a2c-ba3a-af9a4bfd0ef9",
    authority: B2C_AUTHORITY_URL,
    redirectUri: "https://insight.bonver.com",
    accessScope: PROD_ACCESS_SCOPE,
    apiUrl: PROD_BASE_URL,
    zendeskUrl: "https://support.bonver.com",
  },
};

export const isProd = process.env.NODE_ENV === "production";
const current = isProd
  ? ((process.env.NEXT_PUBLIC_DEPLOYMENT_ENV || "kraft") as Env)
  : "local";
const env = envs[current];

export const isTest = current === "test";

export const isDevEnv =
  !isProd ||
  ["local", "test"].includes(process.env.NEXT_PUBLIC_DEPLOYMENT_ENV || "kraft");

export default env;
