import env from "config/env";
import { getAccessToken } from "hooks/use-update-token";
import { ZENDESK_STORAGE_KEY } from "hooks/use-zendesk";
import wretch from "wretch";
import QueryStringAddon from "wretch/addons/queryString";

export const baseApi = wretch(env.apiUrl)
  .addon(QueryStringAddon)
  .defer((wretcher) => {
    try {
      return wretcher.auth(`Bearer ${getAccessToken()}`);
    } catch {
      return wretcher;
    }
  })
  .content("application/json");

export const api = wretch(env.apiUrl)
  .addon(QueryStringAddon)
  .defer((wretcher) => {
    try {
      return wretcher.auth(`Bearer ${getAccessToken()}`);
    } catch {
      return wretcher;
    }
  })
  .content("application/json")
  .resolve((r) => r.json());

export const zendeskApi = wretch(`${env.zendeskUrl}/api/v2`)
  .addon(QueryStringAddon)
  .defer((wretcher) => {
    try {
      return wretcher.auth(
        `Bearer ${window.localStorage.getItem(ZENDESK_STORAGE_KEY)}`,
      );
    } catch {
      return wretcher;
    }
  })
  .content("application/json")
  // TODO: Clear token on 401/403
  .resolve((r) => r.json());

export const defaultFetcher = async (
  path: string | [url: string, query: any],
) => {
  if (typeof path === "string") return api.url(path).get();
  return api.url(path[0]).query(path[1]).get();
};
