import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import "@fontsource/dm-sans";
import { ToastCloseButton } from "components/shared/toast-close-button";
import { msalConfig } from "config/auth";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { api } from "lib/api";
import * as gtag from "lib/gtag";

import { isTest } from "config/env";
import "nanoreset";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import NextNProgress from "nextjs-progressbar";
import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SWRConfig } from "swr";
import "../styles/globals.css";

dayjs.locale("en-gb");

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  // @ts-ignore
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    // @ts-ignore
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const swrOptions = {
  fetcher: async (args: string | any[]) => {
    if (Array.isArray(args)) {
      const [path, query] = args;

      return api.url(path).query(query).get();
    }
    return api.url(args).get();
  },
};

const progressOptions = {
  showSpinner: false,
};

function Bonver({ Component, pageProps: { ...pageProps } }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (window.location.hostname == "admin.bonver.dev") {
      window.location.href = "https://insight.bonver.com";
    }
  }, []);

  return (
    <>
      <Head>
        {isTest && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
            }}
          />
        )}
      </Head>
      {isTest && (
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
      )}

      <DefaultSeo title="Bonver Logistics" />
      <NextNProgress
        color="#00ff7f"
        startPosition={0.1}
        stopDelayMs={200}
        options={progressOptions}
        height={2}
      />
      <SWRConfig value={swrOptions}>
        <CookiesProvider>
          <MsalProvider instance={msalInstance}>
            <Component {...pageProps} />
            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar
              closeOnClick
              transition={Slide}
              closeButton={ToastCloseButton}
            />
          </MsalProvider>
        </CookiesProvider>
      </SWRConfig>
    </>
  );
}

export default Bonver;
