import { capitalize } from "utils/capitalize";

export const B2C_CLIENT_ID = "ddcdc39a-98dd-4fcf-ae52-826153f33831";
export const B2C_AUTHORITY_URL =
  "https://bonverb2c.b2clogin.com/bonverb2c.onmicrosoft.com/b2c_1_signin";
export const PROD_ACCESS_SCOPE =
  "https://bonverb2c.onmicrosoft.com/ed1a9502-dad0-49b1-be47-2750509252a8/access_as_user";
export const DEV_ACCESS_SCOPE =
  "https://bonverb2c.onmicrosoft.com/a0818d14-8de1-4f50-af25-796ed0107909/access_as_user";

export const DEV_BASE_URL =
  "https://bonver-customerportal-webapi-test.azurewebsites.net/v1";
export const PROD_BASE_URL =
  "https://bonver-customerportal-webapi-prod.azurewebsites.net/v1";

export const HELP_CENTER_STATUS_COLOR_MAP: { [status: string]: string } = {
  new: "#FF644F",
  open: "#FFA770",
  pending: "#FFC857",
  hold: "#FFD685",
  solved: "#88ADFB",
  closed: "#00656C",
};

export const HELP_CENTER_STATUS_OPTIONS = [
  {
    label: "Status",
    value: "",
  },
  ...Object.keys(HELP_CENTER_STATUS_COLOR_MAP).map((value) => ({
    label: capitalize(value),
    value,
  })),
];

export const FREIGHT_RANGE_OPTIONS = [
  {
    name: "Month",
    value: "month",
  },
  {
    name: "Quarter",
    value: "quarter",
  },
];

export const FREIGHT_COLOR_MAP: { [key: string]: string } = {
  Undefined: "#9eb2b5",
  Unknown: "#9eb2b5",

  // Regions
  Sweden: "#016ba9",
  Finland: "#012d67",
  Norway: "#d52c3c",
  Denmark: "#bd0c2d",
  EU: "#e2b003",
  Worldwide: "#3a8e35",

  // Service types
  B2B: "#ffa770",
  B2C: "#ffc199",
  Box: "#ffc858",
  Charge: "#fed785",
  Courier: "#a292f2",
  Home: "#607d8b",
  Linehaul: "#002561",
  Mail: "#004384",
  PuP: "#00656c",
  Return: "#5f88d2",
  Multi: "#049975",

  // Cost types
  "Freight costs": "#9ea0a1",
  "Fuel surcharges": "#7b7d7d",
  "Other charges": "#6a6b6b",
};
